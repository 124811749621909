<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import { faArrowRightLong } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

const emit = defineEmits(["closeSidebar"]);
const closeBtn: Ref<HTMLButtonElement | undefined> = ref();
const termValue: Ref<string | undefined> = ref();

function reFocus() {
  closeBtn.value?.focus();
}
const router = useRouter();

async function handleSearchInput() {
  if (termValue.value) {
    await router.push({
      path: "/sok",
      query: { q: termValue.value },
    });
    emit("closeSidebar");
  }
}

const isMobile = useMediaQuery("screen and (max-width: 35rem)");

const scale = computed(() => (isMobile.value ? 1.7 : 2.4));
</script>

<template>
  <div class="sidebar">
    <div class="sidebar__top-row">
      <button
        id="close-button"
        ref="closeBtn"
        class="top-row__button"
        aria-label="Lukk sidemeny"
        data-testid="close-sidebar-button"
        @click="$emit('closeSidebar')"
      >
        <KsIcon
          :icon="faXmark"
          class="button__graphic"
          :scale="scale"
        />
      </button>
    </div>
    <div class="sidebar__content" data-testid="sidebar-content">
      <div class="content__search">
        <label for="searchfield" class="sidebar__title">SØK</label>
        <div class="search__input">
          <input
            id="searchfield"
            v-model="termValue"
            type="search"
            class="input__text text-green-50"
            @keydown.enter="handleSearchInput"
          />
          <button
            :aria-label="`søk på ${termValue} i hele nettsiden`"
            class="input__submit"
            @click="handleSearchInput"
          />
        </div>
      </div>
      <nav class="content__teachingmaterial">
        <span class="sidebar__title">LÆREMIDLER</span>
        <ul>
          <li class="teachingmaterial__title">
            <UiSidebarLink
              to="/produktkatalog/aschehoug-univers"
              data-testid="aschehoug-univers-link"
            >
              Aschehoug univers
            </UiSidebarLink>
          </li>
          <li class="teachingmaterial__title">
            <UiSidebarLink to="/produktkatalog/modus">
              Modus
            </UiSidebarLink>
          </li>
          <li class="group transition-all hover:translate-x-5">
            <UiSidebarLink
              to="/produktkatalog"
              class="sidebar__element [&>*]:text-lg xs:[&>*]:text-xl"
            >
              <KsIcon
                :icon="faArrowRightLong"
                class="text-green-30 group-hover:text-yellow-40"
                :scale="1.4"
              />
              <span>Se hele produktkatalogen</span>
            </UiSidebarLink>
          </li>
        </ul>
      </nav>
      <nav>
        <ul class="sidebar__list">
          <li>
            <UiSidebarLink
              to="/arrangementer"
              class="sidebar__element"
              data-testid="events-link"
            >
              <IconsToolbarEvents />
              <span>Arrangementer</span>
            </UiSidebarLink>
          </li>
          <li>
            <UiSidebarLink to="/artikler" class="sidebar__element">
              <IconsToolbarArticles />
              <span>Artikler</span>
            </UiSidebarLink>
          </li>
          <li>
            <UiSidebarLink to="/podkaster" class="sidebar__element">
              <IconsToolbarPodcasts />
              <span>Podkaster</span>
            </UiSidebarLink>
          </li>
          <li>
            <UiSidebarLink to="/kundeservice" class="sidebar__element">
              <IconsToolbarCustomerService />
              <span>Kundeservice</span>
            </UiSidebarLink>
          </li>
          <li>
            <UiSidebarLink
              to="/om-aschehoug"
              class="sidebar__element"
              @blur.prevent="reFocus"
            >
              <span>Om Aschehoug</span>
            </UiSidebarLink>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style scoped lang="scss">
.sidebar {
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: $green-30;
  width: 100vw;
  animation-name: slide-in;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;

  .sidebar__top-row {
    border-bottom: $thin-border;
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: flex-end;
    padding-inline: 1.5rem;

    @media screen and (min-width: $tablet) {
      padding-inline: 0;
      max-width: calc($max-width / 2);
    }

    .top-row__button {
      cursor: pointer;

      .button__graphic {
        color: $seagreen-40;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    width: 50vw;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0px);
  }
}

.sidebar__content {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  margin: 2rem auto;
  z-index: 1000;

  @media screen and (min-width: $extra-small) {
    max-width: 30rem;
  }

  @media screen and (min-width: $tablet) {
    max-width: calc($max-width / 2 - 15rem);
    margin: 4rem auto 4rem 5rem;
  }

  a {
    text-decoration: none;
    color: $white;
  }

  .sidebar__title {
    color: $seagreen-40;
    font-size: pixelsToRem(18);
    letter-spacing: pixelsToRem(3);

    @media screen and (min-width: $extra-small) {
      font-size: pixelsToRem(20);
    }
  }
  .content__search {
    border-bottom: $thin-border;
    display: flex;
    flex-flow: column nowrap;

    .search__input {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .input__submit {
        border: 1px solid $white;
        background-color: $white;
        min-width: 4rem;
        min-height: 2.95rem;
        font-size: pixelsToRem(10);
        background-image: url("/icons/SearchIcon.svg");
        background-size: 2.5rem;
        background-repeat: no-repeat;
        background-position: 60%;
        transform: translate(0, -0.5rem);
        border-top-right-radius: pixelsToRem(12);
        border-bottom-right-radius: pixelsToRem(12);

        @media screen and (min-width: $extra-small) {
          min-height: 3.6rem;
        }

        &:hover {
          background-color: theme("colors.green.35");
          border: 1px solid theme("colors.green.35");
        }
      }

      .input__text {
        font-size: pixelsToRem(20);
        padding: 0.7rem;
        border-top-left-radius: pixelsToRem(12);
        border-bottom-left-radius: pixelsToRem(12);
        margin: 1rem 0 2rem 0;
        border: 1px solid $white;
        width: 100%;

        @media screen and (min-width: $extra-small) {
          padding: 1rem;
        }
      }
    }
  }

  .content__teachingmaterial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2rem;

    @media screen and (min-width: $extra-small) {
      padding-top: 3rem;
    }

    .teachingmaterial__title {
      color: $white;
      font-size: pixelsToRem(24);
      margin-top: 1rem;

      @media screen and (min-width: $extra-small) {
        font-size: pixelsToRem(34);
      }

      &:hover {
        span {
          color: theme("colors.yellow.40");
        }
      }
    }
  }

  .sidebar__list {
    li {
      border-bottom: $thin-border;
    }

    li:nth-child(1) {
      border-top: 1px solid $seagreen-40;
      margin-top: 1.5rem;
    }

    li:nth-child(5) {
      span {
        grid-column: 1 / span 2;
      }
    }
  }

  .sidebar__element {
    display: grid;
    grid-template-columns: 1fr 5fr;
    justify-items: center;
    align-items: center;
    color: $white;
    font-size: pixelsToRem(22);
    margin: 1.5rem 0;

    @media screen and (min-width: $extra-small) {
      font-size: pixelsToRem(28);
    }

    span {
      margin-left: 2rem;
      width: 100%;
    }

    &:hover {
      color: theme("colors.yellow.40");
    }
  }
}
</style>
