<script setup lang="ts">
import type { ArticleCard } from "@/types/articles";
import type { ColumnsArticlesType } from "@/types/enums";

const props = withDefaults(
  defineProps<{
    article: ArticleCard;
    widthFactor?: ColumnsArticlesType;
  }>(),
  {
    widthFactor: 3,
  },
);

const columns = computed<string>(() => {
  if (props.widthFactor === 2) {
    return "two";
  }
  if (props.widthFactor === 3) {
    return "three";
  }
  return "four";
});
</script>

<template>
  <NuxtLink
    v-if="article"
    class="article-card hover:contrast-75"
    :class="`article-card--${columns}-cols  ${
      article?.colorTheme
    } ${
      widthFactor === 4
        ? 'article-card--row-direction'
        : 'article-card--default-direction'
    }`"
    :to="article.slug.full"
    data-testid="article-card"
  >
    <figure class="article-card__figure">
      <img
        v-if="article.thumbnailImage"
        :src="article.thumbnailImage.img"
        :alt="article.thumbnailImage.alt"
        class="figure__graphic"
      />
      <img
        v-else-if="article.cover"
        :src="article.cover.img"
        :alt="article.cover.alt"
        class="figure__graphic"
      />
      <div v-else class="figure__letter">
        <IconsGraphicsLetter
          :letter="getFirstLetter(article.title)"
          :color-theme="article.colorTheme"
        />
      </div>
    </figure>
    <div
      :class="`article-card__text-container ${
        widthFactor === 2 && 'article-card__text-container--top-translate'
      }`"
    >
      <span
        :class="`text-container__tags ${
          widthFactor === 4
            ? 'text-container__tags--lefted'
            : 'text-container__tags--centered'
        }`"
      >
        <UiCommonsTag :article="article" />
      </span>
      <h3 class="article-card__title">
        {{ article.title }}
      </h3>
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss">
.article-card {
  color: $white;
  display: flex;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  align-self: stretch;
  text-decoration: none;
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: center;

  &:focus {
    border: 0.2rem solid theme("colors.blue.50");
  }

  &:hover {
    .article-card__text-container {
      max-width: 100%;
    }

    .article-card__figure img,
    .article-card__figure svg {
      transform: scale(1.03);
    }
  }

  &__text-container {
    width: 100%;
    max-width: calc(100vw - 2.4rem);
    max-height: 6.5rem;
    z-index: 5;
    padding: 1rem 0;
    background: var(--background);
    color: $white;
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: $small) {
      max-width: 30rem;
    }

    &--top-translate {
      margin-top: -2rem;
    }

    .text-container__tags {
      display: block;
      text-align: center;

      &--centered .tag {
        justify-content: center;
      }

      &--lefted .tag {
        justify-content: flex-start;

        p {
          font-size: pixelsToRem(12);
          line-height: pixelsToRem(15);
          font-weight: 400;
          margin: 0;

          @media screen and (min-width: $extra-small) {
            font-size: pixelsToRem(12);
          }
        }

        @media screen and (min-width: $extra-small) {
          justify-content: center;
        }
      }
    }
  }

  .article-card__title {
    margin: 0.2rem 1rem 1rem 0;
    text-align: center;
    font-size: pixelsToRem(16);
  }

  .article-card__figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 10rem;
    flex-grow: 1;

    .figure__graphic {
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: transform 0.3s ease-in-out;
      object-fit: cover;

      img {
        transition: transform 0.3s ease-in-out;
      }
    }

    .figure__letter {
      overflow: hidden;
      transition: transform 0.3s ease-in-out;
      width: 100%;
      object-fit: cover;

      svg {
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  &--two-cols {
    width: 24rem;
    height: 28rem;

    @media screen and (min-width: $tablet) {
      width: 35rem;
      height: 28rem;
    }

    &:hover {
      .article-card__text-container {
        padding: 2rem;

        @media screen and (min-width: $tablet) {
          padding-inline: 4.4rem;
        }
      }
    }

    .article-card__text-container {
      padding: 1.4rem;
      min-height: 10rem;
      max-width: 20rem;

      @media screen and (min-width: $tablet) {
        padding: 2rem;
        max-width: 30rem;
      }

      .article-card__title {
        font-size: pixelsToRem(22);
        line-height: pixelsToRem(28);
        font-weight: 600;

        @media screen and (min-width: $tablet) {
          font-size: pixelsToRem(24);
          line-height: pixelsToRem(32);
        }
      }
    }
    .article-card__text-container--top-translate {
      margin-top: 0;
      transform: translateY(-4rem);

      @media screen and (min-width: $extra-small) {
        margin-top: -2rem;
        transform: translateY(0px);
      }
    }
  }

  &--three-cols {
    width: 80%;
    height: 18.5rem;
    flex-flow: column nowrap;
    justify-content: space-between;
    background-color: var(--foreground);

    @media screen and (min-width: $extra-small) {
      width: 15rem;
      height: 22rem;
    }

    @media screen and (min-width: $tablet) {
      width: 22rem;
      height: 19rem;
    }

    .article-card__text-container {
      padding: 1rem 1rem;
      min-height: 8rem;

      @media screen and (min-width: $extra-small) {
        min-height: 10rem;
      }

      @media screen and (min-width: $tablet) {
        padding: 1rem 2rem;
        height: 19rem;
        min-height: 8rem;
      }

      .article-card__title {
        margin: 0.4rem 0;
        font-size: pixelsToRem(18);
        line-height: pixelsToRem(24);
        font-weight: 600;
      }
    }

    .article-card__figure {
      padding: 0;
      min-height: 8rem;

      @media screen and (min-width: $tablet) {
        min-height: 10rem;
      }
    }
  }

  &--four-cols {
    width: calc(100vw - 3rem);
    height: auto;
    background-color: var(--background);

    .article-card__figure {
      flex: 1;
      min-height: 4rem !important;
      background-color: var(--foreground);

      .figure__graphic {
        display: flex;
        height: 100%;
        object-fit: cover;
        justify-content: center;
        align-items: stretch;

        img {
          height: 100%;
          object-fit: cover;
          border-bottom: 2px solid transparent;
        }
      }

      .figure__letter {
        width: 100%;

        svg {
          transform: scale(1.1);
        }
      }
    }

    .article-card__text-container {
      flex: 2;
      padding: 0.2rem 0.5rem;
      height: 100%;

      @media screen and (min-width: $extra-small) {
        min-height: 8rem;
      }

      .text-container__tags,
      .article-card__title {
        text-align: left;
        font-size: pixelsToRem(18);
        font-weight: 500;

        @media screen and (min-width: $extra-small) {
          text-align: center;
          margin: 0.5rem 1rem;
          line-height: pixelsToRem(26);
        }
      }
    }

    @media screen and (min-width: $extra-small) {
      width: 16.85rem;
      height: 16.5rem;

      .article-card__figure {
        flex: 1;
      }

      .article-card__text-container {
        flex: 1;
      }
    }
  }

  &--default-direction {
    flex-flow: column nowrap;
  }

  &--row-direction {
    flex-flow: row nowrap;

    @media screen and (min-width: $extra-small) {
      flex-flow: column nowrap;
    }
  }

  @media screen and (min-width: $small) {
    max-height: none;
  }
}
</style>
