<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import { faArrowRightLong } from "@fortawesome/pro-light-svg-icons";
import type { ArticleCard } from "~/types/articles";
import { ARTICLE_CARD_COLUMNS } from "~/types/enums";

const props = defineProps<{
  background?: string;
  title?: string;
  showAll?: boolean;
  noTopMargin?: boolean;
  range?: [number, number];
  articles?: ArticleCard[];
}>();

const actualRange = computed<[number, number]>(() => {
  const max = Math.max(...ARTICLE_CARD_COLUMNS);
  if (!props.range) {
    return [0, max];
  }

  const [start, end] = props.range;

  if (end - start > max) {
    return [start, start + max];
  }

  return props.range;
});

// FIXME: Only fetch if there are no articles provided through props
const cardsQuery = useArticleCards();

const cards = computed(() => {
  const [start, end] = actualRange.value;
  const data = props.articles ?? cardsQuery.data.value ?? [];
  return data.slice(start, end);
});
</script>

<template>
  <section
    v-if="cards.length > 0"
    class="m-0 w-screen px-0 py-8"
    :class="[background]"
  >
    <h2
      v-if="title"
      class="text-versal-lg mx-auto my-4 w-content-article max-w-[95%] text-center tablet:my-8 tablet:w-content tablet:text-left"
      :class="{
        'mt-0': noTopMargin,
        'text-center': !showAll,
      }"
      v-text="title"
    />
    <div
      class="mx-auto flex w-full max-w-content flex-wrap items-stretch justify-center gap-8 tablet:justify-between tablet:gap-0"
      :class=" {
        'tablet:justify-start': cards.length < 3,
      }"
    >
      <CardsArticle
        v-for="article in cards"
        :key="article.id"
        :article="article"
        :width-factor="ARTICLE_CARD_COLUMNS.find((it) => it === cards.length)"
      />
    </div>
    <NuxtLink
      v-if="showAll"
      class="transition-translate mx-auto my-8 flex max-w-content-article items-center justify-center gap-4 text-inherit no-underline duration-200 ease-in-out hover:translate-x-3 tablet:mb-16 tablet:mt-12 tablet:max-w-content tablet:justify-start"
      to="/artikler"
    >
      <KsIcon
        :icon="faArrowRightLong"
        aria-hidden="true"
        :scale="1.4"
      />
      <span class="text-base font-medium xs:text-xl">Se alle artikler</span>
    </NuxtLink>
  </section>
</template>
