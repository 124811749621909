<script lang="ts" setup="">
import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { KsIcon } from "@aschehoug/kloss";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

defineProps<{
  href: string;
  provider: SocialProvider;
  label?: string;
  scale?: number;
}>();

const mapping = {
  facebook: faFacebookF,
  instagram: faInstagram,
  linkedin: faLinkedinIn,
  x: faXTwitter,
  tiktok: faTiktok,
  youtube: faYoutube,
} satisfies Record<SocialProvider, IconDefinition>;
</script>

<template>
  <a
    :href="href"
    :aria-label="label || `Lenke til Aschehougs ${provider}-side`"
    class="flex items-center rounded-full p-[0.3rem] leading-none"
  >
    <KsIcon :icon="mapping[provider]" :scale="scale" />
  </a>
</template>
