<script setup lang="ts">
const settings = useSettingsStore();
</script>

<template>
  <ul v-if="settings.some" class="m-0 flex w-full gap-4 p-0">
    <template v-for="provider in SOCIAL_PROVIDERS" :key="provider">
      <li v-if="settings.some[provider]">
        <UiSocialLink
          class="bg-white text-green-50"
          :provider="provider"
          :href="settings.some[provider]!"
        />
      </li>
    </template>
  </ul>
</template>
