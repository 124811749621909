<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { faBagShopping, faFaceSmile } from "@fortawesome/pro-solid-svg-icons";

const cart = useCartStore();

const auth = useAuthorizeStore();

const sidebar = reactive({
  show: false,
});

function toggleSidebar() {
  sidebar.show = !sidebar.show;
}

const isMobile = useMediaQuery("screen and (max-width: 35rem)");

const scaleModifier = computed(() => (isMobile.value ? 0.8 : 1));
</script>

<template>
  <header class="fixed top-0 z-50 h-24 w-screen border-b border-b-green-30 bg-green-50 print:hidden">
    <div class="mx-auto flex h-full w-content max-w-[calc(100vw-3rem)] items-center justify-between gap-4">
      <NuxtLink
        to="/"
        class="w-fit [&>svg]:w-36 xs:[&>svg]:w-auto"
        aria-label="Aschehoug logo"
      >
        <IconsToolbarLogo fill="white" aria-hidden="true" />
      </NuxtLink>
      <nav class="flex items-center gap-6 text-white xs:gap-10">
        <NuxtLink
          :to="
            auth.loggedIn
              ? '/minside'
              : `/login?redirectTo=${encodeURIComponent('/minside')}`
          "
          class="flex"
          aria-label="min profil"
        >
          <KsIcon
            :icon="faFaceSmile"
            :scale="scaleModifier * 1.4"
          />
        </NuxtLink>
        <NuxtLink
          to="/handlekurv"
          class="relative flex"
          :aria-label="
            cart.isEmpty
              ? 'handlekurv'
              : `handlekurv, det finnes ${cart.count} produkter i handlekurven din`
          "
        >
          <KsIcon
            :icon="faBagShopping"
            :scale="scaleModifier * 1.4"
          />
          <span
            v-if="!cart.isEmpty"
            class="absolute -bottom-3 right-3 flex size-5 items-center justify-center rounded-full bg-yellow-40 text-xs font-semibold text-black-80 xs:-bottom-4 xs:right-4 xs:size-7"
            aria-live="polite"
            :aria-label="`Totalt ${cart.count} produkter i handlekurven`"
            data-dd-privacy="mask"
            v-text="cart.count"
          />
        </NuxtLink>
        <button
          :aria-expanded="sidebar.show"
          aria-haspopup="true"
          class="flex"
          aria-label="Åpne sidebar"
          data-testid="open-sidebar-button"
          @click="toggleSidebar"
        >
          <KsIcon
            :icon="faBars"
            :scale="scaleModifier * 1.85"
          />
        </button>
        <div
          v-if="sidebar.show"
          class="fixed left-0 top-0 size-full bg-[#D15C5C]/80"
          aria-hidden="true"
        />
        <LayoutSidebar
          v-if="sidebar.show"
          aria-expanded="true"
          :show="sidebar.show"
          @close-sidebar="toggleSidebar"
        />
      </nav>
    </div>
  </header>
</template>
